import {mapUi} from "./constants";
import GoogleMapReact from "google-map-react";
import {Images} from "assets";

const GOOGLE_MAP_REACT_API_KEY = { key: "AIzaSyBrhWs8H36A3m5NzHewZ9wTZrD2b_aoO-8" };
const INITIAL_LOCATION = { lat: 34.12286654854215, lng: -118.25425047116484 };
const CUSTOM_MAP_OPTIONS = { styles: mapUi, fullscreenControl: false, };

const CustomMarker = ({ children }) => <>{children}</>;

export const Map = ( ) =>{
    return(
        <div>
            <section className="connect-map">
                <GoogleMapReact
                    bootstrapURLKeys={GOOGLE_MAP_REACT_API_KEY}
                    defaultZoom={14}
                    defaultCenter={INITIAL_LOCATION}
                    options={CUSTOM_MAP_OPTIONS}
                >
                    <CustomMarker  lat={34.12274263314375} lng={-118.25426218650553} >
                        <div className="marker-pin">
                            <img src={Images.Location} alt="" />
                        </div>
                    </CustomMarker>
                </GoogleMapReact>
            </section>
        </div>
    )
}
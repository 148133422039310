import {HomeFooter, HomeHead, HomeMain} from "./fragments";

export const Home = () => {
    return (
        <div className='home-page'>
            <HomeHead/>
            <HomeMain/>
            <HomeFooter/>
        </div>
    )
}
import Electrical from './images/jpeg/electical.webp'
import Electric from './images/jpeg/electric.webp'
import Projects from './images/jpeg/projects.webp'
import Contacts from './images/jpeg/contacts.webp'

// SVG
import Mail from './images/svg/mail.svg'
import Phone from './images/svg/phone.svg'
import Time from './images/svg/time.svg'
import License from './images/svg/license.svg'
import Dart from './images/svg/dart.svg'

import Home from './images/svg/home.svg'
import Elector from './images/svg/elector.svg'
import Energy from './images/svg/energy.svg'
import Check from './images/svg/check.svg'
import Logo from './images/svg/logo.svg'
import NotFound from './images/svg/notFound.svg'
import After from './images/svg/after.svg'
import Before from './images/svg/before.svg'
import Processing from './images/svg/processing.svg'
import Location from './images/svg/location.svg'

import ContactLocation from './images/contactUs/contactLocation.svg'
import ContactEmail from './images/contactUs/contactmail.svg'
import ContactPhone from './images/contactUs/contactPhone.svg'
import ContactTime from './images/contactUs/contactTime.svg'

import {PartnersLogos} from "./images/partnersLogos";


export const Images = {
    PartnersLogos,

    Electrical,
    Electric,
    Projects,
    Contacts,

    // SVG
    Mail,
    Phone,
    Time,
    License,
    Dart,
    Home,
    Elector,
    Energy,
    Check,
    ContactLocation,
    ContactEmail,
    ContactPhone,
    ContactTime,
    Logo,
    NotFound,
    After,
    Before,
    Processing,
    Location
}
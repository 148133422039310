import {Images} from "assets";
import Elector from "../../../../assets/images/svg/elector.svg";

export const Cards = [
    {
        id: 1,
        icon: Images.Dart,
        title: 'Over 40 Years of Experience',
        subLess: 'Albrik, the founder of the company has over 40 years of experience in the field. Our team has worked on residential and... ',
        subAll: 'Albrik, the founder of the company has over 40 years of experience in the field. Our team has worked on residential and commercial projects ranging from simple lighting to complete rewiring of commercial facilities, panel installations and more.'
    },
    {
        id: 2,
        icon: Images.License,
        title: 'Licensed, Bonded and Insured',
        subLess:'ALBRIK ELECTRIC CO <br/> License # 1091781<br/> C-10 ELECTRICAL',
    },
    {
        id: 3,
        icon: Images.Dart,
        title: 'Guarantees on our Work',
        subLess: 'We guarantee our workmanship for 5 years. Any materials and equipment will only...',
        subAll: 'We guarantee our workmanship for 5 years. Any materials and equipment will only be covered under the manufacturer’s warranties.'
    },
]

export const ServiceCard = [
    {
        id: 1,
        icon: Images.Home,
        title: 'Electrical Plan Preparation',
        links: [
            'Commercial Electrical Plans',
            'Residential Electrical Plans',
            'Existing Plan Reviews',
            'Existing Plan Modifications',
        ]
    },
    {
        id: 1,
        icon: Images.Energy,
        title: 'Residential Electrical Installations',
        links: [
            'Lighting & Receptacles',
            'Electric Car Chargers',
            'Panels & Sub-Panels',
            'A/C Unit Circuits',
            'Security Cameras',
            'Pool Pump Circuits',
        ]
    },
    {
        id: 1,
        icon: Images.Elector,
        title: 'Commercial Electrical Installations',
        links: [
            'Lighting & Receptacles',
            '3 Phase Motors & Compressors',
            '3 Phase A/C Units',
            'Panels & Sub-Panels',
            'Commercial Appliance Circuits',
        ]
    },
]

export const WhyCards = [
    {text:'We are Licensed, Bonded and Insured'},
    {text:'Highly Trained and Certified Personnel'},
    {text:'Safety and Quality are our Priority'},
    {text:'Customer Satisfaction Guaranteed'},
]

export const TrustUs = [
    {name:'George John', job:'Job position', info: 'Great service and professional quality work. I was very pleased Great service and…', icon:Images.Electric},
    {name:'George John', job:'Job position', info: 'Great service and professional quality work. I was very pleased Great service and…', icon:Images.Electric},
    {name:'George John', job:'Job position', info: 'Great service and professional quality work. I was very pleased Great service and…', icon:Images.Electric},
    {name:'George John', job:'Job position', info: 'Great service and professional quality work. I was very pleased Great service and…', icon:Images.Electric},
    {name:'George John', job:'Job position', info: 'Great service and professional quality work. I was very pleased Great service and…', icon:Images.Electric},
]
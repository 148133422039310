import AmericanStandard from "./americanStandard.png";
import Eaton from "./eaton.png";
import Fluke from "./fluke.png";
import Klein from "./klein.png";
import Koa from "./koa.jpg";
import Siemens from "./siemens.png";
import Southwest from "./southwest.jpg";
import Square from "./square.png";

export const PartnersLogos = {
   AmericanStandard,
   Eaton,
   Fluke,
   Klein,
   Koa,
   Siemens,
   Southwest,
   Square
};

import {Logo, MainButton} from "components";
import {Link} from "@mui/material";
import SwipeableTemporaryDrawer from "./fragemnts/drower";
import {ScrollToContext} from "utils";
import {useContext} from "react";
import {useNavigate} from "react-router-dom";

export const TopBar = () => {
    const link = window.location.pathname
    const navigate = useNavigate();
    const { handleScrollTo } = useContext(ScrollToContext);

    const handleRequestAnEstimateButn = () => {
        navigate("/contact-us");
        handleScrollTo("REQUEST_AN_ESTIMATE");
    };


    return (
        <div className='top-bar-fragment'>
            <div className='top-bar-wrapper'>
                <div>
                    <Logo/>
                </div>
                <div className='desktop'>
                    <div className='flex-align-center'>
                        <div className='links-wrapper'>
                            <Link className={link === '/' ? 'active-link' : 'passive-link'} href={'/'}> Home </Link>
                            {/*<Link className={link === '/projects' ? 'active-link' : 'passive-link'}*/}
                            {/*      href={'/projects'}> Projects </Link>*/}
                            <Link className={link === '/contact-us' ? 'active-link' : 'passive-link'}
                                  href={'/contact-us'}> Contact Us </Link>
                        </div>
                        {/*<a href="contact-us#request">*/}
                            <MainButton handleClick={handleRequestAnEstimateButn} text={'Request an Estimate'}/>
                        {/*</a>*/}
                    </div>
                </div>
                <div className='mobile'>
                    <SwipeableTemporaryDrawer/>
                </div>
            </div>
        </div>
    )
}
import {Images} from "assets";
import {useWidth} from "utils";
import {useEffect, useState} from "react";

export const HomeHead = () => {
    const width = useWidth();
    const [animation, setAnimation] = useState(false);
    useEffect(() => setAnimation(true), []);

    const animationStyle = animation ? "slide-up" : "";

    return (
        <div className="split-animation">
            <div style={{backgroundImage: `url( ${Images.Electrical} )`}}
                 className='home-background'>

                <div className='home-page-wrapper'>


                    <div className="split">
                        <div className={`split-item split-1 ${animationStyle}`}>
                            <h1 className='page-title'>
                                <span className='orange-text'>Expert Electricians</span> of
                                <br/> Los Angeles County
                            </h1>
                        </div>
                    </div>


                    <div className="split">
                        <div className={`split-item split-2 ${animationStyle}`}>
                            <p className='page-subtitle'>
                                We handle the most challenging electrical installation
                            </p>
                        </div>
                    </div>


                    <div className="split">
                        <div className={`split-item split-3 ${animationStyle}`}>
                            <div className='home-information'>
                                <div className='information-wrapper'>
                                    <div className='contact-wrapper'>
                                        <img src={Images.Phone} alt='icon'/>
                                        <a className='info-style' href="tel:8185120606">(424) 333-0143</a>
                                    </div>
                                    <div style={width < 768 ? {border: 'none', marginRight: 0} : {}}
                                         className='contact-wrapper'>
                                        <img src={Images.Mail} alt='icon'/>
                                        <a style={width < 768 ? {marginRight: 0} : {}} className='info-style'
                                           href="mailto:info@albrikelectric.com">info@albrikelectric.com</a>
                                    </div>
                                    <div className='time-wrapper'>
                                        <img src={Images.Time} alt='icon'/>
                                        <p className='info-style'>Weekdays 7 am – 4 pm</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
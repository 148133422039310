import {BestPracticeCards, WhyCards, ServiceCards, TrustCards} from "./core";
import {Images} from "assets";
import {Partners} from "./core/partners";
import {useAnimation, useWidth} from "utils";

export const HomeMain = () => {
    const width = useWidth()
    const whyWidth = width < 768 ? 2500 : 1800
    const animation = useAnimation(whyWidth);
    const animationStyle = animation ? "slide-up" : "";
    const theyWidth = width < 768 ? 3000 :
        width >= 768 && width < 1440 ? 2600 : 2550
    const animationPar = useAnimation(theyWidth);
    const animationParStyle = animationPar ? "slide-up" : "";

    return (
        <div className="split-animation">
            <div className='home-page-main'>
                <div className='home-page-main-wrapper'>
                    <p className='main-title'>How We Serve You The
                        <br/>
                        <span className='orange-text'> Best Practice</span>
                    </p>
                    <BestPracticeCards/>
                </div>
            </div>


            <div className='service-height'>
                <div className='service-wrapper'>
                    <p className='service-title'>Services <span className='orange-text'>We Provide</span></p>
                    <p className='service-subtitle'> Explore the range of services we have to offer. Below is the list
                        of the most common services, feel free to contact us if you are interested in services not
                        listed.</p>
                    <div>
                        <ServiceCards/>
                    </div>
                </div>
            </div>


            <div className='why-choose-us'>
                <img className='electric-image' src={Images.Electric} alt='icon'/>
                <div className='why-info-wrapper'>
                    <div className='title-and-text'>
                        <div className='flex-column-start'>
                            <p className='title'>Why Choose Us</p>
                            {WhyCards.map((i, k) => (
                                <div key={k} style={{width: '100%'}} className="split">
                                    <div className={`split-item split-${k + 1} ${animationStyle}`}>
                                        <div className='why-cards' key={k}>
                                            <img src={Images.Check} alt='icon'/>
                                            <p>{i.text}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>


            {/*<div className='trust-us'>*/}
            {/*    <p className='main-title'>They<span className='orange-text'> Trust Us</span></p>*/}
            {/*    <div className='trust-us-cards'>*/}
            {/*        <TrustCards/>*/}
            {/*    </div>*/}
            {/*</div>*/}


            <div style={{width: '100%'}} className="split">
                <div className={`split-item split-1 ${animationParStyle}`}>
                    <div className='partners'>
                        <p className='main-title'>Partners We <span className='orange-text'>    Work With</span>
                        <br/>
                            <span className='orange-text'>&</span><br/>
                            Brands We <span className='orange-text'>Trust</span>
                        </p>
                        <div className='partners-subtitle'>
                            <p>
                                We keep track of industry trends and make sure you are receiving the highest quality
                                products
                                with the latest technologies
                            </p>
                        </div>

                        <Partners/>

                    </div>
                </div>
            </div>

        </div>
    )
}
import {Route, Routes} from "react-router-dom";
import {Home, NotFound, Projects, Contacts, TermAndCondition} from "pages";

export const Routers = () =>{
    return(
        <div>
            <Routes>
                <Route path={'/'} element={<Home/>}/>
                {/*<Route path={'/projects'} element={<Projects/>}/>*/}
                <Route path={'/contact-us'} element={<Contacts/>}/>
                {/*<Route path={'/terms-conditions'} element={<TermAndCondition/>}/>*/}
                <Route path='*' element={<NotFound />} />
            </Routes>
        </div>
    )
}
import {useState} from "react";
import {useForm} from "react-hook-form";
import axios from "axios";
import {toast} from "react-toastify";
import {MainButton} from "components";
import {useWidth} from "utils";

export const Form = () => {
    const width = useWidth();
    const {handleSubmit, register, reset,} = useForm();
    const [loader, setLoader] = useState(false)

    const onSubmit = data => {
        setLoader(true)
        axios.post(`https://albrikelectric.com/api/mailer/estimate`, data).then(() => {
            toast("Estimate was sent")
            setLoader(false)
            reset()
        }).catch(() => {
            toast("Something went wrong")
            setLoader(false)
        })
    }

    return (
        <div className='form-fragment'>
            <div>
                <h1 style={{textAlign: 'start'}} className='main-title'>Request an <span
                    className='orange-text'>estimate</span></h1>
                <p className='form-subtitle'>
                    All estimate requests will be reviewed by one of our expert technicians and response will be sent
                    within 24 hours. For larger projects or custom solutions we may ask for more information or schedule
                    a site visit in order to better understand the project requirements.
                </p>
                <form style={{width: '100%'}} onSubmit={handleSubmit(onSubmit)} className='column'>
                    <div className='input-wrapper'>
                        <div className='input-right'>
                            <p> First Name </p>
                            <input {...register("firstName")} type="text" required={true}/>
                        </div>

                        <div>
                            <p>Last Name </p>
                            <input type="text"  {...register("lastName")} required={true}/>
                        </div>
                    </div>

                    <div className='input-wrapper'>
                        <div className='input-right'>
                            <p> Phone Number </p>
                            <input type="number" {...register("phoneNumber")} required={true}/>
                        </div>

                        <div>
                            <p> Email </p>
                            <input type="email"  {...register("email")} required={true}/>
                        </div>
                    </div>

                    <div className='input-wrapper'>
                        <div className='input-right'>
                            <p> Address </p>
                            <input type="text" {...register("address")} required={true}/>
                        </div>

                        <div>
                            <p> Preferred Contact Time </p>
                            <input type={"time"}  {...register("time")} required={true}/>
                        </div>
                    </div>

                    <div className='input-wrapper'>
                        <div>
                            <p> Description of the project </p>
                            <textarea
                                // placeholder={'Add Brief Description'}
                                {...register("description")}
                                      required={true}/>
                        </div>
                    </div>
                    <MainButton
                        styles={width < 768 ? {width: '100%', marginTop: '35px'} : {marginTop: '35px', width: '150px'}}
                        loader={loader}
                        text={'Submit'} type={'submit'}
                    />
                </form>
            </div>

        </div>
    )
}
import {Images} from "assets";
import {MainButton} from "../../components";
import {useNavigate} from "react-router-dom";

export const NotFound = ( ) =>{
    const navigate = useNavigate()

    const handleChangePage = ( ) =>{
        navigate('/')
    }

    return(
        <div className='not-found-page'>


            <div className='info-wrapper'>
                <img src={Images.NotFound} alt="icon"/>

                <div className='not-found-texts'>
                    <p className='title'>Ooops!</p>
                    <p className='sub'>Page not found on server</p>
                    <p className='info'>We can't find the page you are looking for.</p>
                    <MainButton text={'Go to Home'} handleClick={handleChangePage}/>
                </div>
            </div>
        </div>
    )
}
import {Images} from "assets";
import {TextField} from "@mui/material";
import {MainButton} from "components";
import {Controller, useForm} from "react-hook-form";
import axios from "axios";
import {useState} from "react";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ContactUs = () => {
    const {handleSubmit, control, register, reset,} = useForm();
    const [loader, setLoader] = useState(false)

    const onSubmit = data => {
        setLoader(true)
        axios.post(`https://albrikelectric.com/api/mailer/contactForm`, data).then(() => {
            toast("Message was sent")
            setLoader(false)
            reset()
        }).catch(() => {
            setLoader(false)
        })
    }

    return (
        <div className='contact-us-fragment'>
            <div className='contact-us-wrapper'>
                <div className='space-between'>
                    <div className='contact-us-section'>
                        <p className='title'>Connect With Us</p>
                        <a className='links' href="tel:8185120606">
                            <img src={Images.ContactPhone} alt='icon'/>
                            (424) 333-0143
                        </a>
                        <a className='links' href="mailto:info@albrikelectric.com">
                            <img src={Images.ContactEmail} alt='icon'/>
                            info@albrikelectric.com
                        </a>
                        <a
                            target={'_blank'}
                            href={`https://www.google.com/maps?saddr=My+Location&daddr=34.12294648400354,-118.2541860981538`}
                            rel="noreferrer"
                            className='links'>
                            <img src={Images.ContactLocation} alt='icon'/>
                            3673 San Fernando Rd # 311
                            Glendale CA, 91204
                        </a>
                        <p className='links' >
                            <img src={Images.ContactTime} alt='icon'/>
                            Every Day (except holidays) Hours: 7am - 4pm
                        </p>
                    </div>
                    <div className='talk-section'>
                        <p className='main-subtitle'>
                            Feel free to reach out with any questions <br/>or to schedule an onsite visit.
                        </p>
                        <div className='form-wrapper'>
                            <form onSubmit={handleSubmit(onSubmit)} className='column'>
                                <Controller
                                    name={'name'}
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <TextField
                                            {...register('name', {require: true})}
                                            required={true}
                                            InputLabelProps={{
                                                shrink: !!value,
                                            }}
                                            onChange={onChange}
                                            value={value}
                                            label={'Name'}
                                            style={{width: '100%'}}
                                            variant="standard"
                                            id="standard-basic"
                                        />
                                    )}
                                />
                                <Controller
                                    name={'email'}
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <TextField
                                            {...register('email', {require: true})}
                                            required={true}
                                            type={'email'}
                                            InputLabelProps={{
                                                shrink: !!value,
                                            }}
                                            onChange={onChange}
                                            value={value}
                                            style={{width: '100%'}}
                                            variant="standard"
                                            id="standard-basic"
                                            label={'Email'}
                                        />
                                    )}
                                />
                                <Controller
                                    name={'message'}
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <TextField
                                            {...register('message', {require: true})}
                                            required={true}
                                            InputLabelProps={{
                                                shrink: !!value,
                                            }}
                                            value={value}
                                            onChange={onChange}
                                            label={'Message'}
                                            style={{width: '100%'}}
                                            variant="standard"
                                            id="standard-basic"
                                        />
                                    )}
                                />
                                <MainButton loader={loader} text={'Send'} styles={{width: '100%', marginTop: '50px'}} type='submit'/>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import {CircularProgress} from "@mui/material";

export const MainButton = ({text, handleClick, styles, type, loader}) => {
    return (
        <button
            type={type ? type : 'button'}
            style={{...styles}}
            className='main-button'
            onClick={handleClick}
        >
            {loader === true ?
                    <CircularProgress style={{color: 'white', width:'20px', height:'20px'}}/>
                    :
                    text
            }
        </button>

    )
}
import {useState} from "react";
import {Cards} from "./constants";
import {ReadMore} from "components";
import {useAnimation, useWidth} from "utils";

export const BestPracticeCards = ({ }) => {
    const [cardId, setCardId] = useState(null)
    const width = useWidth();
    const renderWidth  = width <= 768 ? 0 : width >= 768 && width <= 1280 ? 200 : 400
    const animation = useAnimation(renderWidth);
    const animationStyle = animation ? "slide-up" : "";

    const handleShowAll = (id) => {
        const selectedId = id === cardId ? null : id
        setCardId(selectedId)
    }

    return (
        <div className='align-start-column'>
            {Cards.map((i, k) => (
                <div key={k} style={{width:'100%'}} className="split">
                    <div className={`split-item split-${k + 1} ${animationStyle}`}>
                        <div style={k === 2 ? {marginRight:0}: {} } key={k} className='items'>
                            <img src={i.icon} alt='icon'/>
                            <p className='item-title'>{i.title}</p>
                            {i.subAll ?
                                <p className='item-sub-title'>
                                    {cardId === i.id ? i.subAll : i.subLess}
                                    <ReadMore
                                        text={cardId === i.id ? 'Read less' : 'Read More'}
                                        handleClick={() => handleShowAll(i.id)}
                                    />
                                </p>
                                :
                                <p className='item-sub-title' dangerouslySetInnerHTML={{__html: i.subLess}}/>
                            }
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}
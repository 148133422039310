import {Routers} from "./router/route";
import {Footer, TopBar} from "fragments";
import {ToastContainer} from "react-toastify";

function App() {

    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
    }

    return (
        <div className="split-animation">
            <ToastContainer position={'bottom-right'}/>
            <TopBar/>
            <Routers/>
            <Footer/>
        </div>
    );
}

export default App;

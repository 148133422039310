import * as React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {Link} from "@mui/material";
import {MainButton} from "../../../components";

export default function SwipeableTemporaryDrawer() {
    const [state, setState] = React.useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({...state, [anchor]: open});
    };

    const list = (anchor) => (
        <div className='anchor-fragment'>
            <div className='close-button'>
                <CloseIcon onClick={toggleDrawer(anchor, false)} style={{fontSize: '40px', color: '#37474F'}}/>
            </div>


            <div className={'anchor-links'}>
                <Link onClick={toggleDrawer(anchor, false)} href={'/'}> Home </Link>
                <Link onClick={toggleDrawer(anchor, false)} href={'/projects'}> Projects </Link>
                <Link onClick={toggleDrawer(anchor, false)} href={'/contact-us'}> Contact Us </Link>
                <div  onClick={toggleDrawer(anchor, false)}>
                 <MainButton text={'Request an Estimate'} handleClick={() => handleOpenEstimate(anchor)}/>
                </div>
            </div>
        </div>
    );

    const handleOpenEstimate = (anchor ) => {
        toggleDrawer(anchor, false)
    }

    return (
        <div className='drower-styles'>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)}>
                        <MenuIcon style={{fontSize: '40px', color: '#37474F'}}/>
                    </Button>
                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </div>
    );
}
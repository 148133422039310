import {ServiceCard} from "./constants";
import {useAnimation} from "utils";

export const ServiceCards = ({}) => {
    const animation = useAnimation(1000);
    const animationStyle = animation ? "slide-up" : "";

    return (
        <div>
            <div className='align-start-column'>
                {ServiceCard.map((i, k) => (
                    <div key={k} className="split">
                        <div className={`split-item split-${k + 1} ${animationStyle}`}>
                            <div style={k === 2 ? {marginRight:0} : {}} className='service-card' key={k}>
                                <div className='service-card-title-image'>
                                    <div className="image-title">
                                        <img src={i.icon} alt='icon'/>
                                        <div className='mobile-desktop'>
                                            <p className='title'>{i.title}</p>
                                        </div>
                                    </div>

                                    <div className='service-card-info'>
                                        <div className='only-tablet'>
                                            <p className='title'>{i.title}</p>
                                        </div>

                                        <div className='line'/>
                                        <div>
                                            <div className='links-wrapper'>
                                                {i.links.map((item, x) => (
                                                    <div key={x} className='link-and-circle'>
                                                        <div className='circle'/>
                                                        <p className='links' key={x}>{item}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
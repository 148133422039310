import {useContext, useEffect, useRef, useState} from "react";
import {Form, Map} from "./fragments";
import {ContactUs} from "../contactUs/contactUs";
import {ScrollToContext, useAnimation} from "utils";
import {Images} from "assets";

export const Contacts = () => {
    const animationPar = useAnimation(100);
    const animationParStyle = animationPar ? "slide-up" : "";
    const [animation, setAnimation] = useState(false);
    const animationStyle = animation ? "slide-up" : "";
    const {scrollTo, handleScrollTo} = useContext(ScrollToContext);
    const estimateRequestRef = useRef(null);

    useEffect(() => {
        if (window.scrollY === 0) {
            setAnimation(true)
        }
    }, [window.scrollY]);

    useEffect(() => {
        if (scrollTo === "REQUEST_AN_ESTIMATE") {
            estimateRequestRef.current.scrollIntoView({behavior: "smooth"});
        }

        return () => handleScrollTo("");
    }, [scrollTo]);

    return (
        <div className="split-animation">
            <div className='contact-page'>
                <div style={{height: '100px', marginBottom: '-100px'}}/>
                <div style={{backgroundImage: `url( ${Images.Contacts} )`}} className='contact-background'>
                    <div className='contact-page-wrapper'>

                        <div className="split-animation">
                            <div className="split">
                                <div className={`split-item split-1 ${animationStyle}`}>
                                    <h1 className='page-title'>
                                        Contact Us
                                    </h1>
                                </div>
                            </div>

                            <div className="split">
                                <div className={`split-item split-2 ${animationStyle}`}>
                                    <p className='contact-subtitle'>
                                        Feel free to reach out with any questions regarding your project. We are always
                                        glad to
                                        provide
                                        a free estimates for projects of all sizes. Whether you have a small repair or
                                        need a
                                        quote for
                                        a commercial project you can count on our team of professionals to get the job
                                        done!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{width: '100%'}} className="split">
                    <div className={`split-item split-opacity-4 ${animationParStyle}`}>
                        <ContactUs/>
                    </div>
                </div>

                <Map/>
                <div ref={estimateRequestRef}/>

                <div id='request'>
                    <Form/>
                </div>
            </div>
        </div>
    )
}
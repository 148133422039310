import {Logo} from "components";
import {Link} from "@mui/material";

export const Footer = () => {
    const link = window.location.pathname

    return (
        <div className='footer-fragment'>
            <div className='wrapper-max'>
                <div className='footer-logo'>
                    <Logo/>
                </div>

                <div className='links-wrapper'>
                    <Link className={link === '/' ? 'active-link' : 'passive-link'} href={'/'}> Home </Link>
                    {/*<Link className={link === '/projects' ? 'active-link' : 'passive-link'}*/}
                    {/*      href={'/projects'}> Projects </Link>*/}
                    <Link className={link === '/contact-us' ? 'active-link' : 'passive-link'}
                          href={'/contact-us'}> Contact Us </Link>
                </div>

                <hr className='hr'/>

                <div className='copyright'>
                    <p>©ALBRIK ELECTRIC CO 2022. All rights reserved.</p>
                    {/*<Link className={link === '/terms-conditions' ? 'active-link' : 'passive-link'}*/}
                    {/*      href={'/terms-conditions'}> Terms & Conditions </Link>*/}
                </div>
            </div>
        </div>
    )
}